<template>
  <div class="_wrap">

    <phoneMenu :menu="p_menu" :p_current="0"></phoneMenu>



    <div class="_auto">
      <h4></h4>

      <div class="_list">
        <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <div class="_left">
            <img :src="$IMG_URL + item.cover" alt="">
          </div>
          <div class="_right">
            <div class="_r_t">
              <div class="_h4">{{ item.title }}</div>
              <div class="_time">
                <span>发布日期：{{ item.release_date }}</span>
                <span class="_shu"></span>
                <img src="@/assets/images/yj.png" alt="">
                <span>{{ item.hits }}</span>
              </div>


            </div>
            <div class="_js">
              {{ item.description }}
            </div>

            <div class="_xq">查看详情</div>
          </div>
        </div>
      </div>


      <div class="_page">
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
          @pagination="getList" />
      </div>
    </div>


    <fixIcon />
    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { getDataById, hits } from '../../api/getData';
import phoneMenu from '../../components/phoneMenu/phoneMenu.vue';

export default {
  components: {
    phoneMenu
  },
  data() {
    return {
      list: [],
      total: 100,
      queryParams: {
        page: 1,
        page_num: 6,
      },
      p_menu: [
        {
          text: "学术动态",
          path: "/study"
        },
        {
          text: "学术委员会",
          path: "/studyCom"
        }, {
          text: "书刊精选",
          path: "/studyBook"
        },
      ]
    }
  },
  async mounted() {
    await this.getList()
    this.$scrollTop()
  },
  methods: {
    async getList() {
      const params = {
        cate_id: 31,
        page: this.queryParams.page,
        page_num: this.queryParams.page_num,
        keywords: '',
        type_id: '',
        times: '',
      }
      await getDataById(params).then(res => {
        console.log(res)
        this.list = res.data.data
        this.total = res.data.total

      })
    },
    goDetail(item) {
      let url = window.origin

      if (item.link != null && item.link != '') {
        const params = {
          id: item.id
        }
        hits(params)
        window.open(item.link)
      }else{
        window.open(`${url}/studyDetail?tit=学术动态&id=${item.id}`)

      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._page
{
  text-align: center;
}

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

._wrap
{
  width: 100%;
  background-color: #F8F5EE;

  ._auto
  {
    width: 85%;
    margin: 0 auto;
    margin-top: 200px;

    h4
    {
      @include tit("../../assets/images/h4-18.png");
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
}

._list
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ._li
  {
    display: flex;
    cursor: pointer;
    width: 48%;
    margin-bottom: 80px;

    ._left
    {
      width: 340px;
      height: 191px;
      overflow: hidden;

      img
      {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s;
      }

      img:hover
      {
        transform: scale(1.1);
      }
    }

    ._right
    {
      // flex: 1;
      width: 420px;
      margin-left: 20px;

      ._r_t
      {
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 10px;

        ._h4
        {
          font-size: 24px;
          margin-bottom: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }


        ._time
        {
          display: flex;
          align-items: center;
          color: #979BA0;

          ._shu
          {
            display: inline-block;
            width: 1px;
            height: 12px;
            background-color: #ccc;
            margin: 0 16px;
          }
        }
      }

      ._js
      {
        color: #333;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        -o-text-overflow: ellipsis;    
        text-overflow: ellipsis;
        margin-top: 10px;
        // height: 40px;

      }

      ._xq
      {
        width: 120px;
        height: 36px;
        background-image: url("../../assets/images/lingx.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        line-height: 36px;
        color: white;
        text-align: center;
        margin-top: 28px;
      }


    }
  }
}

._wrap
{
  @include respondTo('phone')
  {
    ._auto
    {
      width: 95% !important;
      padding: 0;
      margin-top: 30px;

      >h4
      {
        background-image: url("../../assets/images/phone/xsdt.png");
        width: 173px;
        height: 30px;
        margin-bottom: 20px;
      }

      ._list
      {
        display: block;

        ._li
        {
          width: 100%;
          display: block;
          margin-bottom: 30px;

          ._left
          {
            width: 100%;
          }

          ._right
          {
            margin-left: 0;
            width: 100%;
            ._r_t
            {
              margin-top: 10px;

              ._h4
              {
                margin-bottom: 5px;
              }
            }

            ._xq
            {
              display: none;
            }
          }
        }
      }
    }
  }

}
</style>